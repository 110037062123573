.header {
  background-color: #3d8bbb;
  height: 60px;
  display: flex;
  padding-left: 51px;
  align-items: center;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 34%);
  display: flex;
  justify-content: space-between;
  padding-right: 51px;
  /*background-image: url(../../images/header.png);
    background-size: cover;*/
  position: relative;
  z-index: 10;
}
.header_p {
  margin: 0;
  font-family: "Roboto Flex";
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: left;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.header-button {
  border-radius: 15px;
  border: none;
  padding: 13px;
  background-color: white;
  color: black;
}
.header-button_p {
  margin: 0;
  margin: 0;
  font-family: "Roboto Flex";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: left;
}

.header-button:hover {
  color: #3d8bbb;
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 16%);
}

.header_icon {
  width: 40px;
}
.header_block {
  display: flex;
}
