.output-page_button {
    color: white;
    background-color: #3d8bbb;
    border: none;
    width: 150px;
    padding: 10px;
    border-radius: 12px;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.03em;
    text-align: center;
    border: 1px solid #ccc;
}

.output-page_button:hover {
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 34%);
}

.output {
    overflow-y: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-size: 15px;
}