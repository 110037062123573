.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  font-family: "Roboto Flex";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content form div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-content form label {
  margin-bottom: 5px;
  font-family: "Roboto Flex";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: left;
  min-width: 101.77px;
}
.modal-content form select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.modal-content form select:focus {
  border-color: #3d8bbb;
  outline: none;
}
.modal-content form input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
.modal-content form input:focus {
  border-color: #3d8bbb;
  outline: none;
}

.modal-content form button {
  padding: 10px;
  background-color: #3d8bbb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto Flex";
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
  margin-top: 20px;
}

.modal-content form button:hover {
  background-color: #3d8bbb9a;
}

.close-button {
  padding: 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #c82333;
}
