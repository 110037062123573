.page {
    min-height: 100%;
    padding-bottom: 0px;
    width: 100%;
    margin: 0;
    background-color: #fff;
    font-family: "Work Sans";
    font-style: normal;
    box-sizing: border-box;
    
  }
  
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }