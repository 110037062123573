.template-box {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  justify-content: center;
}
.template-page_p {
  font-family: "Roboto Flex";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
  border-bottom: 1.5px solid #808080ab;
  color: grey;
  padding: 5px;
}

.template-page_block {
  display: flex;
  flex-direction: row;
  width: 81.2%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -35px;
}
@media (max-width: 1400px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
  }
  .message-form {
    min-width: 300px;
  }
}

@media (max-width: 4000px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 70px;
  }
  .message-form {
    min-width: 300px;
  }
}

@media (max-width: 1750px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 60px;
  }
  .message-form {
    min-width: 300px;
  }
}

@media (max-width: 1650px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 50px;
  }
  .message-form {
    min-width: 300px;
  }
}

@media (max-width: 1300px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
  }
  .message-form {
    min-width: 250px;
  }
}

@media (max-width: 1100px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
  }
  .message-form {
    min-width: 200px;
  }
}
@media (max-width: 900px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
}

@media (max-width: 600px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
}

@media (max-width: 400px) {
  .template-box {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }
}

.template-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
.template-page_button {
  border: none;
  width: 150px;
  padding: 10px;
  border-radius: 12px;
  font-family: "Roboto Flex";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
  color: white;
  background-color: #3d8bbb;
  border: 1px solid #ccc;
}
.template-page_button:hover {
  box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 34%);
}
